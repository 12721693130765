.layout {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}
.react-datepicker {
  z-index: 1000 !important;
}

body{
  font-family: circular !important;
}

@media (min-width:320px) and (max-width:1024px) {

  /* smartphones, iPhone, portrait 480x320 phones */
  .main-content {
    padding: 0px !important;
  }
}